import * as React from "react"
// import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"


import { Container, Row, Col } from "react-bootstrap"



const IndexPage = () => (
  <Layout>
    <Seo title="Contact" />

    
    <div className="hawaiianBG d-flex justify-content-center py-3">
      <h1>Contact Tik-e Tours</h1>
    </div>
    <Container className="p-4">
    
    
    <Row className="g-5">
      <Col lg={8}>
        <p>
          You can find us located on the western side of Rarotonga in the village of Arorangi, directly opposite Crown Beach Resort.
        </p>
        <p>
          We are open Monday to Friday from 8:30am to 4pm and by appointment only on the weekends.
        </p>
        <p>T:  +682 28687</p>
        <p>M: +682 53686</p>
        <p>E: <a href="mailto:info@tik-etours.com">info@tik-etours.com</a></p>

        <div class="ratio ratio-16x9">
          <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d29754.286908692477!2d-159.828688!3d-21.22051!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x77bb8e7f80ef8e1c!2sTik-e%20Tours!5e0!3m2!1sen!2sus!4v1662562516060!5m2!1sen!2sus" width="600" height="450"  title="map" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div>

      </Col>
      <Col lg={4}>
        <p className="lead">Recent Press</p>
        <p>
          <a href="http://www.nzherald.co.nz/travel/news/article.cfm?c_id=7&objectid=11731227">
            <StaticImage
              src="../images/nz-herald.png"
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="New Zealand Herald Logo"
            />
          </a>
        </p>
        <p>
          <a href="https://www.usatoday.com/story/travel/destinations/2017/04/01/cook-islands/99882942/">
            <StaticImage
              src="../images/large_USAToday_Logo_Square.jpg"
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="New Zealand Herald Logo"
            />
          </a>
        </p>
        <p>
          <a href="https://kiwiliving.nz/holidays/mike-in-rarotonga">
            <StaticImage
              src="../images/kiwi-living.jpg"
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="New Zealand Herald Logo"
            />
          </a>
        </p>
        
      </Col>
    </Row>
    
    </Container>
      
  </Layout>
)

export default IndexPage
